// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animations.tweenEngineGreenSock */
const { engine } = tweenEngine;
/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * Position base animation object
 * @param {Array<HTMLElement>|HTMLElement} elements DOM elements
 * @param {Number} [duration=1.0]
 * @param {Number} [delay=0]
 * @param {Object} params
 * @returns {TweenMax}
 */
function animation(elements, duration, delay, params) {
  params.duration = duration || 0;
  params.delay = delay || 0;

  return engine.tween(elements, params, [
    //GSAP Propriety
    'drawSVG',
    //General SVG Animatable props (http://oli.jp/2010/css-animatable-properties/)
    'fill',
    'fill-opacity',
    'flood-color',
    'lighting-color',
    'marker-offset',
    'stop-color',
    'stop-opacity',
    'stroke',
    'stroke-dasharray',
    'stroke-dashoffset',
    'stroke-miterlimit',
    'stroke-opacity',
    'stroke-width',
    'viewport-fill',
    'viewport-fill-opacity',
  ]);
}

factory.registerAnimation('BaseSVG', animation);

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animationGroup from './base/animationGroup';
import '../animations/base/baseScale';
import '../animations/animations/editorFadeOut';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:replaceText',
  render() {
    return React.createElement(
      animationGroup,
      {
        in: { name: 'BaseScale', duration: 1, params: { from: { scale: 3 } } },
        out: { name: 'editorFadeOut', duration: 1 },
      },
      this.props.children,
    );
  },
});

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animationGroup from './base/animationGroup';
import '../animations/animations/gfppIn';
import '../animations/animations/gfppOut';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:gfppAnimation',
  //propTypes: {
  //    repeat: React.PropTypes.string
  //},
  render() {
    const inAnimation = {
      name: 'gfppIn',
      duration: 0.4,
      delay: 0.1,
    };
    //
    const outAnimation = {
      name: 'gfppOut',
      duration: 0.3,
    };

    return React.createElement(
      animationGroup,
      {
        in: inAnimation,
        out: outAnimation,
        component: 'div',
      },
      this.props.children,
    );
  },
});

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animationGroup from './base/animationGroup';
import '../animations/animations/leftBarStaggerIn';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:leftBarAnimation',
  //propTypes: {
  //    repeat: React.PropTypes.string
  //},
  render() {
    const stagger = {
      name: 'leftBarStaggerIn',
      duration: 0.8,
      delay: 0.2,
      params: { stagger: 0.055 },
    };

    return React.createElement(
      animationGroup,
      {
        in: stagger,
        component: 'div',
      },
      this.props.children,
    );
  },
});

// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animations.tweenEngineGreenSock */
const { engine } = tweenEngine;
/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * Interaction base animation helper
 * @param {Array<HTMLElement>|HTMLElement} elements DOM elements
 * @param {Number} [delay=0]
 * @param {Object} params
 * @returns {TweenMax}
 */
function baseInteraction(elements, delay, params) {
  params.duration = 0;
  params.delay = delay || 0;

  return engine.tween(elements, params, ['pointerEvents', 'cursor']);
}

factory.registerAnimation('BaseInteraction', baseInteraction);

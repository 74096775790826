// @ts-nocheck
import _ from 'lodash';
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * FadeOut to opacity 0 animation object
 * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {Object} [params.yPos] the y position of the element
 * @returns {TimelineMax}
 */
function animation(elements, duration, delay, params) {
  elements = elements instanceof window.HTMLElement ? [elements] : elements;
  const sequence = factory.sequence(params);

  const { positionDuration } = params;
  const { fadeDuration } = params;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(elements, function (element) {
    const label_element = element.querySelector(params.selectors.label);
    const action_element = element.querySelector(params.selectors.action);

    sequence
      .add(
        factory.animate('BasePosition', element, positionDuration, delay || 0, {
          to: { y: (params.direction || 1) * 20 },
          ease: 'Cubic.easeIn',
        }),
        0,
      )
      .add(
        factory.animate('BaseFade', label_element, 0, 0, {
          opacity: 0,
          immediateRender: false,
        }),
      )
      .add(
        factory.animate('BaseFade', action_element, 0, 0, {
          opacity: 1,
          immediateRender: false,
        }),
      )
      .add(
        factory.animate('BasePosition', element, positionDuration, 0.2, {
          to: { y: 0 },
          ease: 'Expo.easeIn',
        }),
      )
      .add(
        factory.animate(
          'BaseFade',
          action_element,
          fadeDuration,
          positionDuration + 0.5,
          {
            opacity: 0,
            ease: 'Sine.easeIn',
          },
        ),
      )
      .add(
        factory.animate('BaseFade', label_element, fadeDuration, 0, {
          opacity: 1,
        }),
      );
  });

  return sequence.get();
}

factory.registerAnimation('tabIndication', animation);

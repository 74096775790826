// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * @param {HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters

 * @returns {TimelineMax}
 */
function animation(element, duration, delay, params) {
  const sequence = factory.sequence(params);

  sequence.add(
    factory.animate('BasePosition', element, duration, delay, {
      to: { x: '-100%' },
      ease: 'Cubic.easeOut',
    }),
    0,
  );

  return sequence.get();
}

animation.properties = {
  hideOnStart: false,
};

factory.registerAnimation('leftBarPanelOut', animation);

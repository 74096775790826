// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {Object} [params.direction=left] the direction the panel should fade in *from*
 * Values can be 'top', 'left', 'bottom' or 'right'.
 * Defaults to 'left'
 * @returns {TimelineMax}
 */
function animation(elements, duration, delay = 0, params) {
  // const fadeDuration = duration * 2 / 9;
  // const moveDuration = duration * 4 / 9;
  // const moveUpDelay = duration / 9;
  const fadeDuration = (duration * 2) / 4;
  const moveDuration = duration;
  const moveUpDelay = duration / 4;
  return factory
    .sequence(params)
    .add(
      factory.animate('BaseFade', elements, fadeDuration, delay, {
        from: { opacity: 1 },
        to: { opacity: 0 },
        ease: 'Linear.easeOut',
      }),
      0,
    )
    .add(
      factory.animate('BasePosition', elements, moveDuration, delay, {
        to: { x: -100 },
        ease: 'Quint.easeOut',
      }),
      0,
    )
    .add(
      factory.animate('BaseDimensions', elements, moveDuration, moveUpDelay, {
        to: { height: 0, marginTop: 0 },
        ease: 'Quart.easeInOut',
      }),
      0,
    )
    .get();

  //sequence.add(factory.animate('BaseDimensions', element, 0, delay, {to: {width}}), 0);
}

animation.properties = {
  hideOnStart: true,
};

factory.registerAnimation('listRemove', animation);

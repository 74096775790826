// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * @param {HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {String} params.selector the svg path to animate


 * @returns {TimelineMax}
 */
function animation(element, duration, delay, params) {
  const sequence = factory.sequence(params);
  element = params.selector ? element.querySelector(params.selector) : element;
  sequence.add(
    factory.animate('BaseRotate', element, duration, delay, {
      to: {
        rotation: '360_cw',
        repeat: 0,
      },
    }),
  );
  sequence.add(
    factory.animate('BaseClear', element, 0, 0, {
      props: 'rotation',
      immediateRender: false,
    }),
    '+=0',
  );
  return sequence.get();
}

animation.properties = {
  hideOnStart: false,
};

factory.registerAnimation('rotateStop', animation);

// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import animationGroup from './base/animationGroup';
import '../animations/animations/contentDelete';
import '../animations/animations/contentAdd';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:contentAddRemove',
  propTypes: {
    onContentRemoved: PropTypes.func,
    onContentAdded: PropTypes.func,
  },
  render() {
    const inAnimation = {
      name: 'contentAdd',
      duration: 0.3,
      params: {
        callbacks: {
          onComplete: this.props.onContentAdded || _.noop,
        },
      },
    };

    const outAnimation = {
      name: 'contentDelete',
      duration: 0.3,
      params: {
        callbacks: {
          onComplete: this.props.onContentRemoved || _.noop,
        },
      },
    };

    return React.createElement(
      animationGroup,
      {
        in: inAnimation,
        out: outAnimation,
        component: 'div',
      },
      this.props.children,
    );
  },
});

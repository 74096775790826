// @ts-nocheck
import tweenEngine from '../../../localTweenEngine/localTweenEngine';

/** core.animations.tweenEngineGreenSock */
const { engine } = tweenEngine;
/** core.animationsFactory */
const { factory } = tweenEngine;
/**
 * Sequence base animation object
 * @param {Array<HTMLElement>|HTMLElement} elements DOM elements
 * @param {Number} [duration=1.0]
 * @param {Number} [delay=0]
 * @param {Object} params
 * @returns {TimelineMax}
 */
function BaseSequence(params) {
  return engine.timeline(params, []);
}

factory.registerAnimation('BaseSequence', BaseSequence);

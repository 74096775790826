// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animationGroup from './base/animationGroup';
import '../animations/animations/tooltipIn';
import '../animations/animations/tooltipOut';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'Animation:tooltipAnimation',
  render() {
    const inAnimation = {
      name: 'tooltipIn',
      duration: 0,
    };
    const outAnimation = {
      name: 'tooltipOut',
      duration: 0.2,
    };

    return React.createElement(
      animationGroup,
      {
        in: inAnimation,
        out: outAnimation,
        component: 'div',
      },
      this.props.children,
    );
  },
});

// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * @param {HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters

 * @returns {TimelineMax}
 */
function animation(elements, duration, delay, params) {
  const sequence = factory.sequence(params);

  sequence.add(
    factory.animate('BaseFade', elements, duration, delay, {
      from: { opacity: 0 },
      to: { opacity: 1 },
      ease: 'Quint.easeOut',
    }),
    0,
  );
  sequence.add(
    factory.animate('BaseScale', elements, duration, delay, {
      from: { scale: 0 },
      ease: 'Elastic.easeOut',
      easeParams: [1.2, 1],
    }),
    0,
  );
  sequence.add(
    factory.animate('BaseClear', elements, 0, 0, {
      props: 'scale,opacity',
      immediateRender: false,
    }),
  );

  return sequence.get();
}

animation.properties = {
  hideOnStart: true,
};

factory.registerAnimation('gfppIn', animation);

import React from 'react';
import AnimationGroup from './base/animationGroup';
import '../animations/animations/leftBarPanelIn';
import '../animations/animations/leftBarPanelOut';
import styles from './leftBarPanelsAnimation.scss';
import type { LeftBarPanelsAnimationDispatchProps } from './leftBarPanelsAnimationMapper';
import { hoc } from '#packages/util';
import { mapDispatchToProps } from './leftBarPanelsAnimationMapper';

const inAnimation = {
  name: 'leftBarPanelIn',
  duration: 0.5,
  delay: 0,
};

const outAnimation = {
  name: 'leftBarPanelOut',
  duration: 0.4,
  delay: 0.1,
};

export interface LeftBarPanelsAnimationOwnProps {}

interface LeftBarPanelsAnimationProps
  extends LeftBarPanelsAnimationOwnProps,
    LeftBarPanelsAnimationDispatchProps {}

const LeftBarPanelsAnimationComponent: React.FC<
  LeftBarPanelsAnimationProps
> = ({ children, initDomMeasurementsAfterAnimationEnd }) => (
  <AnimationGroup
    in={inAnimation}
    out={outAnimation}
    component="div"
    className={styles.wrapper}
    animationClassName={styles.opened}
    animationActiveClassName="animation-active"
    onEntered={initDomMeasurementsAfterAnimationEnd}
  >
    {children}
  </AnimationGroup>
);

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(LeftBarPanelsAnimationComponent);

// @ts-nocheck
import _ from 'lodash';
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * FadeOut to opacity 0 animation object
 * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {Object} [params.direction=left] the direction the panel should fade in *from*
 * Values can be 'top', 'left', 'bottom' or 'right'.
 * Defaults to 'left'
 * @returns {TimelineMax}
 */
function animation(elements, duration, delay, params) {
  elements = elements instanceof window.HTMLElement ? [elements] : elements;
  const sequence = factory.sequence(params);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(elements, function (element) {
    const clientRect = element.getBoundingClientRect();
    sequence.add(
      factory.animate('BaseFade', element, duration, delay || 0, {
        from: { opacity: 0 },
        to: { opacity: 1 },
        ease: 'Cubic.easeOut',
      }),
      0,
    );
    sequence.add(
      factory.animate('BaseDimensions', element, duration, delay || 0, {
        from: { height: 0 },
        to: { height: clientRect.height },
        ease: 'Cubic.easeOut',
      }),
      0,
    );
  });

  return sequence.get();
}

factory.registerAnimation('contentAdd', animation);

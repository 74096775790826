import _ from 'lodash';
import type { AnimateScrollPosition } from 'types/core';
import tweenEngine from '../localTweenEngine/localTweenEngine';

/**
 * Animate scroll the content of an element
 * @param {object} scrollPosition
 * @param {number} [scrollPosition.x]
 * @param {number} [scrollPosition.y]
 * @param {number} [duration=0]
 * @param {number} [delay=0]
 * @param {HTMLElement} [root=window]
 * @param {function} [onComplete]
 * @returns {TweenMax}
 */
function scrollTo(
  scrollPosition: AnimateScrollPosition,
  duration: number = 0,
  delay: number = 0,
  root: HTMLElement,
  onComplete: () => void = _.noop,
) {
  const params = {
    x: scrollPosition.x,
    y: scrollPosition.y,
    callbacks: { onComplete },
  };

  return tweenEngine.factory.animate(
    'BaseScroll',
    root || window,
    duration,
    delay,
    params,
  );
}

export default scrollTo;

// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

const DEFAULT_BORDER_COLOR = '#FFFFFF';

/**
 * @param {HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters

 * @returns {TimelineMax}
 */
function animation(element, duration, delay, params) {
  const prevElement = element.nextSibling;
  const sequence = factory.sequence(params);
  const isSwitching = Boolean(prevElement);

  if (isSwitching) {
    // SWITCHING PANELS
    const prevHeaderElement = prevElement.querySelector(
      '.left-panel-frame-header',
    );

    const fromColor =
      getComputedStyle(prevHeaderElement)?.borderTopColor ??
      DEFAULT_BORDER_COLOR;
    const fromWidth = prevElement.offsetWidth;
    const toWidth = element.offsetWidth;

    if (element.dataset.dynamicWidth) {
      sequence.add(
        factory.animate('BaseDimensions', element, 0.1, delay + duration, {
          from: { width: toWidth },
          to: { width: 'auto' },
        }),
        0,
      );
    }

    sequence.add(
      factory.animate('BaseDimensions', element, duration, delay, {
        from: { width: fromWidth },
        to: { width: toWidth },
        ease: 'Cubic.easeOut',
      }),
      0,
    );

    const headerElement = element.querySelector('.left-panel-frame-header');

    if (headerElement) {
      //sometimes it not exist, e.g. ecommerce could render welcome view without header
      const toColor =
        getComputedStyle(headerElement)?.borderTopColor ?? DEFAULT_BORDER_COLOR;
      sequence.add(
        factory.animate('BaseColor', headerElement, duration, delay, {
          from: { borderTopColor: fromColor },
          to: { borderTopColor: toColor },
          ease: 'Cubic.easeOut',
        }),
        0,
      );
    }
  } else {
    // OPEN PANEL
    sequence.add(
      factory.animate('BasePosition', element, duration, delay, {
        from: { x: '-100%' },
        ease: 'Cubic.easeOut',
      }),
      0,
    );
  }

  sequence.add(
    factory.animate('BaseClear', element, 0, 0, {
      props: 'y,x,opacity',
      immediateRender: false,
    }),
  );

  return sequence.get();
}

animation.properties = {
  hideOnStart: true,
};

factory.registerAnimation('leftBarPanelIn', animation);

import animationManager from './components/base/animationManager';
import spinClick from './components/spinClick';
import blinkClick from './components/blinkClick';
import collapser from './components/collapser';
import replaceText from './components/replaceText';
import panelsAnimation from './components/panelsAnimation';
import rightClickAppearance from './components/rightClickAppearance';
import gfppAnimation from './components/gfppAnimation';
import gfppMoreAnimation from './components/gfppMoreAnimation';
import contentSlide from './components/contentSlide';
import contentSlideCSS from './components/contentSlideCSS';
import dbEditItemSlide from './components/dbEditItemSlide';
import contentAddRemove from './components/contentAddRemove';
import leftBarAnimation from './components/leftBarAnimation';
import leftBarPanelsAnimation from './components/leftBarPanelsAnimation';
import listAddRemove from './components/listAddRemove';
import topBarMenuPanelAnimation from './components/topBarMenuPanelAnimation';
import tooltipAnimation from './components/tooltipAnimation';
import previewAnimationProgress from './components/previewAnimationProgress';
import rotatePartAnimation from './components/rotatePartAnimation';
import tabIndicationAnimation from './components/tabIndicationAnimation';
import scrollTo from './utils/scrollTo';
import './animations/base/sequence/baseSequence';
import './animations/base/baseNone';
import './animations/base/baseFade';
import './animations/base/basePosition';
import './animations/base/baseScale';
import './animations/base/baseSkew';
import './animations/base/baseRotate';
import './animations/base/baseRotate3D';
import './animations/base/baseClip';
import './animations/base/baseDimensions';
import './animations/base/baseScroll';
import './animations/base/baseInteraction';
import './animations/base/baseColor';
import './animations/base/baseSvg';
import './animations/base/baseClear';

export {
  //POC Components
  spinClick,
  blinkClick,
  collapser,
  replaceText,
  //Real Components
  animationManager,
  panelsAnimation,
  rightClickAppearance,
  gfppAnimation,
  gfppMoreAnimation,
  contentSlide,
  contentSlideCSS,
  dbEditItemSlide,
  contentAddRemove,
  leftBarAnimation,
  leftBarPanelsAnimation,
  listAddRemove,
  topBarMenuPanelAnimation,
  tooltipAnimation,
  previewAnimationProgress,
  rotatePartAnimation,
  tabIndicationAnimation,
  //Utilities
  scrollTo,
};

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animationGroup from './base/animationGroup';
import '../animations/base/baseClip';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:Collapser',
  render() {
    return React.createElement(
      animationGroup,
      {
        in: {
          name: 'BaseClip',
          duration: 1,
          params: { from: { clip: 'rect(0,+=0,0,0)' } },
        },
        out: {
          name: 'BaseClip',
          duration: 0.5,
          params: { to: { clip: 'rect(0,+=0,0,0)' } },
        },
        component: 'div',
      },
      this.props.children,
    );
  },
});

import React from 'react';
import animationGroup from './base/animationGroup';
import '../animations/animations/contentSlideIn';
import '../animations/animations/contentSlideOut';

interface Props {
  direction: string;
  duration: number;
  children: React.ReactNode;
}

const ContentSlide: React.FunctionComponent<Props> = (props: Props) => {
  const slideIn = {
    name: 'contentSlideIn',
    duration: props.duration,
    params: { direction: props.direction },
  };

  const slideOut = {
    name: 'contentSlideOut',
    duration: props.duration,
    params: { direction: props.direction },
  };

  return React.createElement(
    animationGroup,
    {
      in: slideIn,
      out: slideOut,
      component: 'div',
    },
    props.children,
  );
};

ContentSlide.defaultProps = {
  direction: 'left',
  duration: 0.3,
};

ContentSlide.displayName = 'UIAnimation:contentSlide';

export default ContentSlide;

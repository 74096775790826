import { domMeasurements } from '#packages/stateManagement';
import type { MapDispatchToProps } from 'types/redux';
import type { LeftBarPanelsAnimationOwnProps } from './leftBarPanelsAnimation';

const { initDomMeasurementsAfterAnimationEnd } = domMeasurements.actions;

export interface LeftBarPanelsAnimationDispatchProps {
  initDomMeasurementsAfterAnimationEnd: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  LeftBarPanelsAnimationDispatchProps,
  LeftBarPanelsAnimationOwnProps
> = {
  initDomMeasurementsAfterAnimationEnd,
};

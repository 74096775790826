// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * FadeOut to opacity 0 animation object
 * @param {Array<HTMLElement>|HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {Object} [params.direction=left] the direction the panel should fade in *from*
 * Values can be 'top', 'left', 'bottom' or 'right'.
 * Defaults to 'left'
 * @returns {TimelineMax}
 */
function animation(element, duration, delay, params) {
  const clientRect = element.getBoundingClientRect();
  const directionMap = {
    left: { x: -clientRect.width },
    right: { x: clientRect.width },
    top: { y: -clientRect.height },
    bottom: { y: clientRect.height },
  };

  const direction = directionMap[params.direction] || directionMap.left;
  delete params.direction;
  const sequence = factory.sequence(params);
  sequence.add(
    factory.animate('BaseFade', element, duration, delay, {
      from: { opacity: 0 },
      to: { opacity: 1 },
      ease: 'Sine.easeOut',
    }),
    0,
  );
  sequence.add(
    factory.animate('BasePosition', element, duration, delay, {
      from: direction,
      ease: 'Cubic.easeOut',
    }),
    0,
  );
  sequence.add(
    factory.animate('BaseClear', element, 0, 0, {
      props: 'x,y',
      immediateRender: false,
    }),
  );

  return sequence.get();
}

animation.properties = {
  hideOnStart: true,
};

factory.registerAnimation('dbPageSlideIn', animation);

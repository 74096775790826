// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { SafeInjectHtml } from '#packages/util';
import * as TransitionGroup from 'react-transition-group';

let cssTemplate = '';
cssTemplate +=
  '.$CSS-enter {-webkit-transform: $DIRECTION($DISTANCE); -moz-transform: $DIRECTION($DISTANCE); transform: $DIRECTION($DISTANCE);}\n';
cssTemplate +=
  '.$CSS-enter.$CSS-enter-active {transition: -webkit-transform $DURATIONs ease-in-out $DELAYs; transition: -moz-transform $DURATIONs ease-in-out $DELAYs; transition: transform $DURATIONs ease-in-out $DELAYs; -webkit-transform: $DIRECTION(0); -moz-transform: $DIRECTION(0); transform: $DIRECTION(0); }\n';
cssTemplate +=
  '.$CSS-leave {-webkit-transform: $DIRECTION(0);  -moz-transform: $DIRECTION(0); transform: $DIRECTION(0); }\n';
cssTemplate +=
  '.$CSS-leave.$CSS-leave-active {transition: -webkit-transform $DURATIONs ease-in-out; transition: -moz-transform $DURATIONs ease-in-out; transition: transform $DURATIONs ease-in-out; -webkit-transform: $DIRECTION($DISTANCE); -moz-transform: $DIRECTION($DISTANCE); transform: $DIRECTION($DISTANCE);}\n';

const directions = {
  up: 'translateY',
  down: 'translateY',
  left: 'translateX',
  right: 'translateX',
};

const positive = {
  down: false,
  up: true,
  left: false,
  right: true,
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:contentSlideCSS',
  propTypes: {
    direction: PropTypes.string,
    component: PropTypes.string,
    distance: PropTypes.string,
    duration: PropTypes.number,
    delay: PropTypes.number,
  },
  getDefaultProps() {
    return {
      component: 'div',
      direction: 'down',
      distance: '100vh',
      duration: 300,
      delay: 0,
    };
  },
  UNSAFE_componentWillMount() {
    this._cssName = _.uniqueId('transition');
  },
  getCSS() {
    return cssTemplate
      .replace(/\$CSS/g, this._cssName)
      .replace(/\$DIRECTION/g, directions[this.props.direction])
      .replace(
        /\$DISTANCE/g,
        (positive[this.props.direction] ? '' : '-') + this.props.distance,
      )
      .replace(
        /\$DURATION/g,
        (parseFloat(this.props.duration) / 1000.0).toFixed(2).toString(),
      )
      .replace(
        /\$DELAY/g,
        (parseFloat(this.props.delay) / 1000.0).toFixed(2).toString(),
      );
  },
  render() {
    let children = this.props.children
      ? React.Children.map(this.props.children, _.identity)
      : [];

    children = [
      React.createElement(SafeInjectHtml, {
        tag: 'style',
        key: 'css',
        html: this.getCSS(),
      }),
    ]
      .concat(children)
      .map((child, i) =>
        React.createElement(
          TransitionGroup.CSSTransition,
          {
            key: i,
            classNames: this._cssName,
            timeout: this.props.duration,
          },
          child,
        ),
      );

    return React.createElement(
      TransitionGroup.TransitionGroup,
      {
        component: this.props.component,
      },
      children,
    );
  },
});

/* eslint-disable  @wix/santa-editor/scoped-imports */
import type { AllPanelNames } from './types';
import type * as SyncPanels from './syncMapping';

function syncPanel(name: keyof typeof SyncPanels): Promise<unknown> {
  return import('./syncMapping').then((m) => m[name]);
}

export const MODULES_MAPPING: Record<AllPanelNames, () => Promise<unknown>> = {
  addPreset: () => import('@/addPreset'),
  compPanels: () => import('@/compPanels'),
  appStudio: () => import('@/appStudio'),
  ascend: () => import('@/ascend'),
  customizeDesignDataPanel: () => import('@/customizeDesignDataPanel'),
  debugTools: () => import('@/debugTools'),
  columnsControls: () => import('@/columnsControls'),
  designerPanels: () => import('@/designerPanels'),
  designPanel: () => import('@/designPanel'),
  dockableSections: () => import('@/dockableSections'),
  localMode: () => import('@/localMode'),
  maskCrop: () => import('@/maskCrop/panels'),
  multilingual: () => import('@/multilingual'),
  notifications: () => import('@/notifications'),
  newDesignPanel: () => import('@/newDesignPanel'),
  platformPanels: () => import('@/platformPanels'),
  promote: () => import('@/promote'),
  ai: () => import('@/ai'),
  sectionizer: () => import('@/sectionizer'),
  videoSearch: () => import('@/videoSearch'),
  welcomeScreen: () => import('@/welcomeScreen'),
  tpaPanels: () => import('@/tpaPanels'),
  wixCode: () => import('@/wixCode'),
  wixBlocksConsumer: () => import('@/wixBlocksConsumer'),

  addPanelInfra: () => syncPanel('addPanelInfra'),
  advancedStylePanel: () => syncPanel('advancedStylePanel'),
  baseUI: () => syncPanel('baseUI'),
  blog: () => syncPanel('blog'),
  componentDeprecation: () => syncPanel('componentDeprecation'),
  interactions: () => syncPanel('interactions'),
  menu: () => syncPanel('menu'),
  mobileEditor: () => syncPanel('mobileEditor'),
  pages: () => syncPanel('pages'),
  panels: () => syncPanel('panels'),
  privateAppsPanel: () => syncPanel('privateAppsPanel'),
  platform: () => syncPanel('platform'),
  rEditor: () => syncPanel('rEditor'),
  appManager: () => syncPanel('appManager'),
  savePublish: () => syncPanel('savePublish'),
  textControls: () => syncPanel('textControls'),
  topBar: () => syncPanel('topBar'),
  tpa: () => syncPanel('tpa'),
  wixBookings: () => syncPanel('wixBookings'),
  wixData: () => syncPanel('wixData'),
  wixStore: () => syncPanel('wixStore'),
  wixApps: () => syncPanel('wixApps'),
  siteReadiness: () => syncPanel('siteReadiness'),
};

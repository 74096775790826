// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import animation from './base/animation';
import '../animations/base/baseRotate';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:spinClick',
  propTypes: {
    cycles: PropTypes.number,
  },
  render() {
    const spinAnimation = {
      name: 'BaseRotate',
      duration: 2,
      params: {
        from: { rotation: 0 },
        to: { rotation: `${(this.props.cycles || 1) * 360}deg` },
      },
    };

    return React.createElement(
      animation,
      {
        click: spinAnimation,
      },
      this.props.children,
    );
  },
});

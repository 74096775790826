// @ts-nocheck
import _ from 'lodash';
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animations.tweenEngineGreenSock */
const { engine } = tweenEngine;
/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * Clearing animation object
 * @param {Array<HTMLElement>|HTMLElement} elements DOM elements
 * @param {Number} [duration=0] Duration has no meaning here, remains for API compliance
 * @param {Number} [delay=0]
 * @param {Object} params
 * @param {String} params.props coma separated props to clear on elements
 * @param {String} [params.parentProps] coma separated props to clear on elements parents
 * @returns {TimelineMax}
 */
function baseClear(elements, duration, delay, params) {
  let parentParams;
  duration = 0;
  elements = elements.length ? elements : [elements];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const parents = _.uniq(_.map(elements, 'parentNode'));

  const elementParams = _.defaults(
    {
      duration,
      delay: delay || 0,
      to: {},
      clearProps: params.props,
    },
    params,
  );

  delete elementParams.props;
  delete elementParams.parentProps;

  if (params.parentProps) {
    parentParams = _.cloneDeep(elementParams);
    parentParams.clearProps = params.parentProps;
  }

  const sequence = factory.sequence();
  sequence.add(engine.tween(elements, elementParams, []));
  if (parentParams) {
    sequence.add(engine.tween(parents, parentParams, []));
  }

  return sequence.get();
}

factory.registerAnimation('BaseClear', baseClear);

import { gsap } from 'gsap';
// import tweenEngine from '@wix/santa-core-utils/dist/tweenEngine3';
import tweenEngine from '@wix/santa-core-utils/dist/ts/coreUtils/tweenEngine/tweenEngine3';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
//@ts-expect-error
import DrawSVGPlugin from '@wix/santa-external-modules/tweenmax-plugins/3.1.1-transition-phase/DrawSVGPlugin';

const tweenEngineAndFactory = tweenEngine.create(gsap, [
  ScrollToPlugin,
  DrawSVGPlugin,
]);

export { tweenEngineAndFactory };

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import animationGroup from './base/animationGroup';
import '../animations/animations/panelIn';
import '../animations/animations/panelOut';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'Animation:panelsAnimation',
  propTypes: {
    direction: PropTypes.string,
  },
  render() {
    const outAnimation = {
      name: 'panelOut',
      duration: 0.2,
    };

    const inAnimation = {
      name: 'panelIn',
      duration: 0,
    };

    const props = _.omit(this.props, 'direction', 'children');
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(props, {
      in: inAnimation,
      out: outAnimation,
      component: 'div',
    });

    return React.createElement(animationGroup, props, this.props.children);
  },
});

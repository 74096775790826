import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

const capturedEvents = {
  click: 'onClick',
  mouseEnter: 'onMouseEnter',
  mouseLeave: 'onMouseLeave',
};

function capturedEventHandler(
  this: AnyFixMe,
  evtType: keyof typeof capturedEvents,
  evt: AnyFixMe,
) {
  const child = React.Children.only(this.props.children);
  if (this.context.animations) {
    this.context.animations.scheduleAnimation(this, this.props[evtType]);
  }
  const callback = child.props[capturedEvents[evtType]];
  if (callback) {
    callback(evt);
  }
}

interface AnimationProps {
  mouseEnter?: unknown;
  mouseLeave?: unknown;
  click?: unknown;
  mount?: unknown;
  in?: unknown;
  out?: unknown;
  component?: string;
  active?: boolean;
  immediatePlay?: boolean;
}

// eslint-disable-next-line react/prefer-es6-class
const Animation = createReactClass<AnimationProps>({
  displayName: 'animation',
  propTypes: {
    mouseEnter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    mouseLeave: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    click: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    mount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    in: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    out: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    active: PropTypes.bool,
    immediatePlay: PropTypes.bool,
  },
  contextTypes: {
    animations: PropTypes.any,
  },
  UNSAFE_componentWillMount() {
    if (this.context.animations) {
      this.context.animations.trackUIAnimation(this);
    }
  },
  componentDidMount() {
    if (this.context.animations) {
      this.context.animations.scheduleAnimation(this, this.props.mount);
    }
  },
  shouldComponentUpdate(nextProps) {
    if (
      (!nextProps.active && this.props.active) ||
      (!nextProps.immediatePlay && this.props.immediatePlay)
    ) {
      this.context.animations.scheduleAnimation(this, this.props.out);
    }
    return nextProps.active;
  },
  componentDidUpdate(prevProps) {
    if (
      (!prevProps.active && this.props.active) ||
      (!prevProps.immediatePlay && this.props.immediatePlay)
    ) {
      this.context.animations.scheduleAnimation(this, this.props.in);
    }
  },

  componentWillUnmount() {
    if (this.context.animations) {
      this.context.animations.untrackUIAnimation(this);
    }
  },

  onClick: _.partial(capturedEventHandler, 'click'),
  onMouseEnter: _.partial(capturedEventHandler, 'mouseEnter'),
  onMouseLeave: _.partial(capturedEventHandler, 'mouseLeave'),

  render() {
    if (!this.props.children) {
      return null;
    }
    const child = React.Children.only(this.props.children);
    const shadowedHandlers = _(capturedEvents)
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      .pick(_.keys(this.props))
      .invert()
      .mapValues(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(function (this: AnyFixMe, propName, eventName) {
          return this[eventName];
        }, this),
      )
      .value();
    return React.cloneElement(child, shadowedHandlers);
  },
});

Animation.defaultProps = {
  active: true,
  immediatePlay: true,
};

export default Animation;

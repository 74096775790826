import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Animation from './animation';
import * as TransitionGroup from 'react-transition-group';

function getTimeoutFromProps(props: AnyFixMe) {
  let timeout = 0;

  if (props) {
    if (props.out && typeof props.out.duration === 'number') {
      timeout = props.out.duration * 1000;
    }
  }

  return timeout;
}

interface AnimationGroupProps {
  onEntered?: (element: HTMLElement) => void;
  mouseEnter?: unknown;
  mouseLeave?: unknown;
  click?: unknown;
  mount?: unknown;
  in?: unknown;
  out?: unknown;
  component?: string;
  className?: string;
  animationClassName?: string;
  animationActiveClassName?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<AnimationGroupProps>({
  displayName: 'animationGroup',
  propTypes: {
    mouseEnter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    mouseLeave: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    click: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    mount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    in: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    out: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    component: PropTypes.string,
    className: PropTypes.string,
  },
  contextTypes: {
    animations: PropTypes.any,
  },

  render() {
    const props = _.omit(this.props as AnimationGroupProps, [
      'component',
      'children',
      'onEntered',
    ]);
    const self = this;
    const timeout = getTimeoutFromProps(props);
    const {
      animationClassName,
      animationActiveClassName,
      onEntered = () => {},
    } = this.props;

    const children = React.Children.map(this.props.children, (child) => {
      const animation = React.createElement(Animation, props, child);
      const transitionProps = {
        timeout,
        onEnter(domElement: HTMLElement) {
          if (self.context.animations) {
            self.context.animations.scheduleAnimation(
              domElement,
              animation.props.in,
            );
          }

          if (animationClassName) {
            domElement?.classList.add(animationClassName);
          }

          if (animationActiveClassName) {
            domElement?.classList.add(animationActiveClassName);
          }
        },
        onEntered(domElement: HTMLElement) {
          if (animationActiveClassName) {
            domElement?.classList.remove(animationActiveClassName);
          }

          onEntered(domElement);
        },
        onExit(domElement: HTMLElement) {
          if (self.context.animations) {
            self.context.animations.scheduleAnimation(
              domElement,
              animation.props.out,
            );
          }

          if (animationClassName) {
            domElement?.classList.remove(animationClassName);
          }

          if (animationActiveClassName) {
            domElement?.classList.add(animationActiveClassName);
          }
        },
        onExited(domElement: HTMLElement) {
          if (animationActiveClassName) {
            domElement?.classList.remove(animationActiveClassName);
          }
        },
      };

      return React.createElement(
        TransitionGroup.Transition,
        transitionProps,
        animation,
      );
    });

    return React.createElement(TransitionGroup.TransitionGroup, {
      children,
      component: this.props.component || 'span',
      className: this.props.className,
    });
  },
});

// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

const FADE_DURATION_PART = 0.2;

/**
 * FadeOut to opacity 0 animation object
 * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {Object} [params.direction=left] the direction the panel should fade in *from*
 * Values can be 'top', 'left', 'bottom' or 'right'.
 * Defaults to 'left'
 * @returns {TimelineMax}
 */
function animation(elements, duration, delay = 0, params) {
  const direction = directionMap[params.direction] || directionMap.left;
  delete params.direction;
  const sequence = factory.sequence(params);

  // Fade only in the end of the animation
  sequence.add(
    factory.animate(
      'BaseFade',
      elements,
      duration * FADE_DURATION_PART,
      duration * (1 - FADE_DURATION_PART) + delay,
      {
        from: { opacity: 1 },
        to: { opacity: 0 },
        ease: 'Sine.easeOut',
      },
    ),
    0,
  );

  sequence.add(
    factory.animate('BasePosition', elements, duration, delay, {
      to: direction,
      ease: 'Cubic.easeOut',
    }),
    0,
  );

  return sequence.get();
}

animation.properties = {
  hideOnStart: false,
};

const directionMap = {
  left: { x: '-100%' },
  right: { x: '100%' },
  top: { y: '-100%' },
  bottom: { y: '100%' },
};

factory.registerAnimation('contentSlideOut', animation);

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animation from './base/animation';
import '../animations/animations/gfppMoreIn';
import '../animations/animations/gfppMoreOut';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:gfppMoreAnimation',
  render() {
    const inAnimation = {
      name: 'gfppMoreIn',
      duration: 0.35,
    };
    //
    const outAnimation = {
      name: 'gfppMoreOut',
      duration: 0.2,
    };

    return React.createElement(
      animation,
      {
        mouseEnter: inAnimation,
        mouseLeave: outAnimation,
        component: 'div',
      },
      this.props.children,
    );
  },
});

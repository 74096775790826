//@ts-expect-error
import { tweenEngineAndFactory } from '%TWEEN_ENGINE_FACTORY%';
/**
 * if the cpu is hogged adjust animations (0 = don't)
 */
//tweenEngine.engine.adjustLagSmoothing(50, 17);
tweenEngineAndFactory.engine.adjustLagSmoothing(0);
tweenEngineAndFactory.engine.useRAF(true);

export default {
  factory: {
    animate: tweenEngineAndFactory.factory.animate,
    sequence: tweenEngineAndFactory.factory.sequence,
    registerAnimation: tweenEngineAndFactory.factory.registerAnimation,
    getProperties: tweenEngineAndFactory.factory.getProperties,
    getAnimationsDefs: tweenEngineAndFactory.factory.getAnimationsDefs,
  },
  engine: {
    timeline: tweenEngineAndFactory.engine.timeline,
    tween: tweenEngineAndFactory.engine.tween,
    set: tweenEngineAndFactory.engine.set,
    kill: tweenEngineAndFactory.engine.kill,
    addTickerEvent: tweenEngineAndFactory.engine.addTickerEvent,
    removeTickerEvent: tweenEngineAndFactory.engine.removeTickerEvent,
    isTweening: tweenEngineAndFactory.engine.isTweening,
    getBoundingRect: tweenEngineAndFactory.engine.getBoundingRect,
    getBoundingContentRect: tweenEngineAndFactory.engine.getBoundingContentRect,
    delayedCall: tweenEngineAndFactory.engine.delayedCall,
    animateTimeScale: tweenEngineAndFactory.engine.animateTimeScale,
  },
};

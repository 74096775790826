._wrapper_16nqv_1 > *:not(._opened_16nqv_1) .left-panel-frame-content {
  opacity: 0;
  transition: opacity 0.5s; }

._wrapper_16nqv_1 > ._opened_16nqv_1 .left-panel-frame-content {
  opacity: 1;
  transition: opacity 0.1s 0.3s; }

._wrapper_16nqv_1 > * + * {
  transform: translate(0, 0) !important;
  opacity: 0;
  transition: opacity 0.1s; }

._wrapper_16nqv_1 > *:first-of-type:nth-last-of-type(2) ~ ._wrapper_16nqv_1 > * > * {
  opacity: 1;
  transition: opacity 0.1s 0.4s; }

import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import animationGroup from './base/animationGroup';
import '../animations/animations/tooltipIn';
import '../animations/animations/tooltipOut';

interface TopBarMenuPanelAnimationProps {
  inAnimationDuration?: number;
  outAnimationDuration?: number;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<TopBarMenuPanelAnimationProps>({
  displayName: 'UIAnimation:topBarMenuPanelAnimation',
  propTypes: {
    inAnimationDuration: PropTypes.number,
    outAnimationDuration: PropTypes.number,
  },
  getDefaultProps() {
    return {
      inAnimationDuration: 0.1,
      outAnimationDuration: 0.2,
    };
  },
  render() {
    const inAnimation = {
      name: 'tooltipIn',
      duration: this.props.inAnimationDuration,
    };
    const outAnimation = {
      name: 'tooltipOut',
      duration: this.props.outAnimationDuration,
    };

    return React.createElement(
      animationGroup,
      {
        in: inAnimation,
        out: outAnimation,
        component: 'div',
      },
      this.props.children,
    );
  },
});

// @ts-nocheck
import ReactDOM from 'reactDOM';
import _ from 'lodash';
import PropTypes from 'prop-types';
import tweenEngine from '../../localTweenEngine/localTweenEngine';

const { animate } = tweenEngine.factory;
const { kill } = tweenEngine.engine;

/**
 * Stub for clearing after animations
 * @param domNode
 * @param callback
 */
function restoreStyle(component, callback) {
  delete component._currentAnimation;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (_.includes(this.animations, component)) {
    callback();
  }
}

export default {
  getInitialState() {
    this.animations = [];
    this.mouseOvers = [];
    return {};
  },

  childContextTypes: {
    animations: PropTypes.any,
  },

  getChildContext() {
    const context = {
      animations: {
        trackUIAnimation: this.trackUIAnimation,
        untrackUIAnimation: this.untrackUIAnimation,
        scheduleAnimation: this.scheduleAnimation,
        cancelRunningAnimation: this.cancelRunningAnimation,
      },
    };
    return context;
  },

  /**
   * Public function to run the animations.
   * Eventually it will be used to add animations into a timeline and tweak their appearance if needed
   * @param component
   * @param animation
   * @param callback
   */
  scheduleAnimation(component, animation, callback) {
    this.runAnimation(component, animation, callback);
  },

  /**
   * Run the animation and if a callback was passed run it when animation completes.
   * Callbacks here are used for componentWillEnter/componentWillLeave to release control
   * back to react.
   * @param component
   * @param animation
   * @param callback
   */
  runAnimation(component, animation, callback) {
    callback = callback || _.noop;

    if (!animation) {
      callback();
      return;
    }

    const domNode = ReactDOM.findDOMNode(component);
    if (!domNode) {
      callback();
      return;
    }

    if (_.isFunction(animation)) {
      animation = animation();
    }

    this.cancelRunningAnimation(component, 1);

    const cb = function (cbType) {
      if (animation.params?.callbacks?.[cbType]) {
        animation.params.callbacks[cbType]();
      }
      restoreStyle.call(this, component, callback);
    };

    component._currentAnimation = animate(
      animation.name,
      domNode,
      animation.duration,
      animation.delay,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({}, animation.params, {
        callbacks: {
          onComplete: cb.bind(this, 'onComplete'),
          onInterrupt: cb.bind(this, 'onInterrupt'),
        },
      }),
    );
  },

  /**
   * Start tracking user actions (click, mouseEnter, mouseLEave etc.)
   * @param anim
   */
  trackUIAnimation(anim) {
    this.animations = _.union(this.animations, [anim]);
  },

  /**
   * Stop tracking user actions (click, mouseEnter, mouseLEave etc.)
   * @param anim
   */
  untrackUIAnimation(anim) {
    // Avi told me to :-)
    this.animations = _.without(this.animations, anim);
    this.cancelRunningAnimation(anim);
    this.mouseOvers = _.without(this.animations, anim);
  },

  cancelRunningAnimation(component, seekTo) {
    if (component._currentAnimation) {
      kill(component._currentAnimation, seekTo || 0);
    }
  },
};

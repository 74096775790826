// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import animationGroup from './base/animationGroup';
import '../animations/animations/dbPageSlideIn';
import '../animations/animations/dbPageSlideOut';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'Animation:DbEditItemSlide',
  propTypes: {
    direction: PropTypes.string,
  },
  render() {
    const dbPageSlideIn = {
      name: 'dbPageSlideIn',
      duration: 0.5,
      params: { direction: this.props.direction },
    };

    const dbPageSlideOut = {
      name: 'dbPageSlideOut',
      duration: 0.5,
      params: { direction: this.props.direction },
    };

    return React.createElement(
      animationGroup,
      {
        mount: dbPageSlideIn,
        out: dbPageSlideOut,
        component: 'div',
      },
      this.props.children,
    );
  },
});

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import animation from './base/animation';
import '../animations/animations/rotatePlay';
import '../animations/animations/rotateStop';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:rotatePartAnimation',
  propTypes: {
    //duration: React.PropTypes.number,
    //interval: React.PropTypes.number,
    play: PropTypes.bool,
    selector: PropTypes.string,
  },
  render() {
    const playAnimation = {
      name: 'rotatePlay',
      duration: 3,

      params: {
        interval: 1,
        selector: this.props.selector,
      },
    };

    const stopAnimation = {
      name: 'rotateStop',
      duration: 0.3,
      params: { selector: this.props.selector },
    };

    return React.createElement(
      animation,
      {
        in: playAnimation,
        out: stopAnimation,
        immediatePlay: this.props.play,
      },
      this.props.children,
    );
  },
});

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animationGroup from './base/animationGroup';
import '../animations/animations/listAdd';
import '../animations/animations/listRemove';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:listAddRemove',
  render() {
    return React.createElement(
      animationGroup,
      {
        out: {
          name: 'listRemove',
          duration: 0.4,
          params: {},
        },
        in: {
          name: 'listAdd',
          duration: 0.5,
          params: {},
        },
        component: 'div',
      },
      this.props.children,
    );
  },
});

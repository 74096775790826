// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * FadeOut to opacity 0 animation object
 * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {Object} [params.direction=left] the direction the panel should fade in *from*
 * Values can be 'top', 'left', 'bottom' or 'right'.
 * Defaults to 'left'
 * @returns {TimelineMax}
 */
function animation(elements, duration, delay, params) {
  elements = elements instanceof window.HTMLElement ? [elements] : elements;
  const sequence = factory.sequence(params);
  sequence.add(
    factory.animate('BaseFade', elements, duration, delay || 0, {
      from: { opacity: 1 },
      to: { opacity: 0 },
      ease: 'Cubic.easeOut',
    }),
    0,
  );
  sequence.add(
    factory.animate('BaseDimensions', elements, duration, delay || 0, {
      to: { height: 0 },
      ease: 'Cubic.easeOut',
    }),
    0,
  );

  return sequence.get();
}

factory.registerAnimation('contentDelete', animation);

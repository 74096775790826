// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animation from './base/animation';
import '../animations/base/baseFade';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'UIAnimation:blinkClick',
  //propTypes: {
  //    repeat: React.PropTypes.string
  //},
  render() {
    const blinkAnimation = {
      name: 'BaseFade',
      duration: 0.5,
      params: {
        from: { opacity: 1 },
        to: { opacity: 0 },
        repeat: 5,
        yoyo: true,
      },
    };

    return React.createElement(
      animation,
      {
        click: blinkAnimation,
      },
      this.props.children,
    );
  },
});

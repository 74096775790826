// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * @param {HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {Object} [params.stagger=0]

 * @returns {TimelineMax}
 */
function animation(element, duration, delay, params) {
  const elements = element.querySelectorAll('.left-bar-item');
  const stagger = params.stagger || 0;
  delete params.stagger;

  const sequence = factory.sequence(params);

  sequence.add(
    factory.animate('BasePosition', elements, duration, delay, {
      from: { x: '-100%' },
      stagger,
      ease: 'Elastic.easeOut',
      easeParams: [1.2, 0.85],
    }),
    0,
  );
  sequence.add(
    factory.animate('BaseFade', elements, duration * 0.2, delay, {
      from: { opacity: 0 },
      to: { opacity: 1 },
      stagger,
    }),
    0,
  );
  sequence.add(
    factory.animate('BaseClear', elements, 0, 0, {
      props: 'y,x,opacity',
      immediateRender: false,
    }),
  );

  return sequence.get();
}

animation.properties = {
  hideOnStart: true,
};

factory.registerAnimation('leftBarStaggerIn', animation);

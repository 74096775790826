import React from 'react';

import animation from './base/animation';
import '../animations/animations/animationPreviewPlay';
import '../animations/animations/animationPreviewStop';

export default class PreviewAnimationProgress extends React.Component<
  { duration: number; playing: boolean },
  undefined
> {
  static displayName = 'UIAnimation:previewAnimationProgress';

  render() {
    const playAnimation = {
      name: 'animationPreviewPlay',
      duration: this.props.duration,
      params: { progressSelector: '.play-progress' },
    };

    const stopAnimation = {
      name: 'animationPreviewStop',
      duration: 0.5,
      params: { progressSelector: '.play-progress' },
    };

    return React.createElement(
      animation,
      {
        click: this.props.playing ? stopAnimation : playAnimation,
      },
      this.props.children,
    );
  }
}

// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animations.tweenEngineGreenSock */
const { engine } = tweenEngine;
/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * Empty animation object
 * @param {Array<HTMLElement>|HTMLElement} elements DOM elements
 * @param {Number} [duration=0]
 * @param {Number} [delay=0]
 * @param {Object} params
 * @returns {TweenMax}
 */
function baseNone(elements, duration, delay, params) {
  params.duration = duration || 0;
  params.delay = delay || 0;
  params.to = {};

  return engine.tween(elements, params, []);
}

factory.registerAnimation('BaseNone', baseNone);

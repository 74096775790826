// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import animationGroup from './base/animationGroup';
import '../animations/animations/tabIndication';

function normalizeDirection(direction) {
  direction = direction || 1;
  direction /= Math.abs(direction);
  return direction;
}

export interface TabIndicationAnimationProps {
  direction: number;
  selectors: AnyFixMe;
  immediatePlay: boolean;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<TabIndicationAnimationProps>({
  displayName: 'UIAnimation:tabIndication',
  propTypes: {
    direction: PropTypes.number,
    selectors: PropTypes.any,
    immediatePlay: PropTypes.bool,
  },
  componentDidMount() {
    this.hasMounted = true;
  },
  getAnimationGroupProps(hasMounted) {
    const direction = normalizeDirection(this.props.direction);

    const tabAnimation = {
      name: 'tabIndication',
      duration: 0,
      delay: 0.05,
      params: {
        selectors: this.props.selectors,
        positionDuration: 0.35,
        fadeDuration: 0.3,
        direction,
      },
    };

    const animationGroupProps = {
      in: tabAnimation,
      immediatePlay: this.props.immediatePlay,
    };

    if (!hasMounted && this.props.immediatePlay) {
      animationGroupProps.mount = tabAnimation;
    }

    return animationGroupProps;
  },
  render() {
    return React.createElement(
      animationGroup,
      this.getAnimationGroupProps(this.hasMounted),
      this.props.children,
    );
  },
});

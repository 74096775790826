// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * FadeOut to opacity 0 animation object
 * @param {Array<HTMLElement>|HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @returns {TimelineMax}
 */
function animation(element, duration, delay, params) {
  const sequence = factory.sequence(params);
  sequence.add(
    factory.animate('BaseFade', element, duration, delay, {
      to: { opacity: 0 },
      ease: 'Sine.easeOut',
    }),
    0,
  );

  return sequence.get();
}

animation.properties = {
  hideOnStart: false,
};

factory.registerAnimation('tooltipOut', animation);

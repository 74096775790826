import React from 'react';
import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { CompRef } from 'types/documentServices';
import { fedopsLogger } from '#packages/util';
import { MODULES_MAPPING } from './mapping';
import type { AllPanelNames } from './types';

const lazyLoaded: Record<string, React.ComponentType<any>> = {};
const lazyLoadedInit: Record<string, LazyComponent[]> = {};

function getPackageNameAndPath(
  packagePath: string,
): [AllPanelNames] | [AllPanelNames, string] {
  const firstDotIndex = packagePath.indexOf('.');
  if (firstDotIndex === -1) {
    return [packagePath as AllPanelNames];
  }

  return [
    packagePath.substr(0, firstDotIndex) as AllPanelNames,
    packagePath.substr(firstDotIndex + 1),
  ];
}

// TODO: clean up it
function onModuleLoaded(moduleName: string) {
  lazyLoadedInit[moduleName].forEach((lazyLoaderComp) => {
    if (typeof lazyLoaderComp.props.onModuleLoaded === 'function') {
      lazyLoaderComp.props.onModuleLoaded(); // if onModuleLoaded exists it will be called instead of re-render the lazy loader
    } else {
      lazyLoaderComp.forceUpdate();
    }
  });
}

interface LazyLoaderProps {
  moduleName: string;
  componentLoader?: () => Promise<React.ComponentType>;
  onModuleLoaded?: () => void;
  id?: string;
  // TODO: cleanup props below
  previewMode?: boolean;
  performingMouseMoveAction?: boolean;
  wixCodeLoaded?: boolean;
  toolsHidden?: boolean;
  isEnabled?: boolean;
  context?: unknown;
  columnsContainer?: unknown;
  selectedComponents?: CompRef[];
  onClose?: () => void;
  onContextMenu?: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export async function loadComponentByPath(
  componentPath: string,
): Promise<React.ComponentType> {
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.LAZY_COMPONENT_LOAD,
    {
      customParams: {
        componentPath,
      },
    },
  );
  const [moduleName, componentSubPath] = getPackageNameAndPath(componentPath);

  if (typeof MODULES_MAPPING[moduleName] !== 'function') {
    throw new Error(`${moduleName} has not on async loader mapping`);
  }

  const module = await MODULES_MAPPING[moduleName]();
  const moduleComponent = _.get(module, componentSubPath);

  if (!moduleComponent) {
    throw new Error(
      `Module [${moduleName}] has not component at ${componentSubPath}. It is possible that this has not yet been implemented in the editor.`,
    );
  }
  fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.LAZY_COMPONENT_LOAD, {
    customParams: {
      componentPath,
    },
  });
  return moduleComponent;
}

export default class LazyComponent extends React.Component<LazyLoaderProps> {
  static isLoaded = (moduleName: AnyFixMe) => _.has(lazyLoaded, moduleName);

  componentDidMount() {
    lazyLoadedInit[this.props.moduleName] =
      lazyLoadedInit[this.props.moduleName] || [];
    lazyLoadedInit[this.props.moduleName].push(this);

    this.loadComponent();
  }

  componentDidUpdate(prevProps: AnyFixMe) {
    if (this.props.moduleName !== prevProps.moduleName) {
      lazyLoadedInit[prevProps.moduleName] = _.without(
        lazyLoadedInit[prevProps.moduleName],
        this,
      );
      lazyLoadedInit[this.props.moduleName] =
        lazyLoadedInit[this.props.moduleName] || [];
      lazyLoadedInit[this.props.moduleName].push(this);

      this.loadComponent();
    }
  }

  componentWillUnmount() {
    lazyLoadedInit[this.props.moduleName] = _.without(
      lazyLoadedInit[this.props.moduleName],
      this,
    );
  }

  loadComponent() {
    const { moduleName: componentNameOrPath, componentLoader } = this.props;
    if (
      !componentNameOrPath ||
      lazyLoaded.hasOwnProperty(componentNameOrPath)
    ) {
      return;
    }

    const componentPromise =
      typeof componentLoader === 'function'
        ? componentLoader()
        : loadComponentByPath(componentNameOrPath);

    componentPromise
      .then((Component) => {
        lazyLoaded[componentNameOrPath] = Component;

        onModuleLoaded(componentNameOrPath);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('LazyComponent', error);
        ErrorReporter.captureException(error, {
          tags: { LazyComponent: true },
          extra: {
            componentNameOrPath,
            componentLoader: typeof componentLoader,
          },
        });
      });
  }

  render() {
    const { moduleName } = this.props;

    if (lazyLoaded[moduleName]) {
      const childProps = _.omit(this.props, ['moduleName', 'onModuleLoaded']);
      return React.createElement(lazyLoaded[moduleName], childProps);
    }
    return null;
  }
}

// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * @param {HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters
 * @param {String} params.progressSelector the svg path to animate


 * @returns {TimelineMax}
 */
function animation(element, duration, delay, params) {
  const sequence = factory.sequence(params);
  const progressElement = element.querySelector(params.progressSelector);
  sequence.add(
    factory.animate(
      'BaseSVG',
      progressElement,
      duration,
      delay,
      { to: { drawSVG: '100%' } },
      0,
    ),
  );
  return sequence.get();
}

animation.properties = {
  hideOnStart: false,
};

factory.registerAnimation('animationPreviewStop', animation);

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import animationGroup from './base/animationGroup';
import '../animations/animations/panelOut';
import '../animations/animations/panelIn';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'Animation:rightClickAnimation',
  render() {
    //var inAnimation = {
    //    name: 'tooltipIn',
    //    duration: 0
    //};
    //var outAnimation = {
    //    name: 'tooltipOut',
    //    duration: 0.2
    //};

    return React.createElement(
      animationGroup,
      {
        //'in': inAnimation,
        //'out': outAnimation,
        component: 'div',
      },
      this.props.children,
    );
  },
});

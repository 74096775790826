// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * @param {HTMLElement} element DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters

 * @returns {TimelineMax}
 */
function animation(element, duration, delay, params) {
  const sequence = factory.sequence(params);
  const more = element.querySelector('.gfpp-more');
  const container = element.querySelector('.gfpp-more-container');
  const width = container.offsetWidth;

  sequence.add(
    factory.animate('BaseDimensions', element, 0, delay, { to: { width } }),
    0,
  );
  sequence.add(
    factory.animate('BaseDimensions', more, duration, delay, {
      to: { width },
      ease: 'Sine.easeIn',
    }),
    0,
  );
  return sequence.get();
}

animation.properties = {
  hideOnStart: false,
};

factory.registerAnimation('gfppMoreIn', animation);

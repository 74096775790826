// @ts-nocheck
import tweenEngine from '../../localTweenEngine/localTweenEngine';

/** core.animationsFactory */
const { factory } = tweenEngine;

/**
 * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
 * @param {Number} [duration]
 * @param {Number} [delay]
 * @param {Object} [params] optional parameters

 * @returns {TimelineMax}
 */
function animation(elements, duration, delay, params) {
  return factory
    .sequence(params)
    .add(
      factory.animate('BaseFade', elements, duration, delay, {
        from: { opacity: 0 },
        to: { opacity: 1 },
        ease: 'Quint.easeOut',
      }),
      0,
    )
    .add(
      factory.animate('BasePosition', elements, duration, delay, {
        from: { x: -100 },
        ease: 'Quint.easeOut',
      }),
      0,
    )
    .get();
}

animation.properties = {
  hideOnStart: true,
};

factory.registerAnimation('listAdd', animation);
